import { useQuery, useMutation } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { queryClient } from "src/App";

const prefix = "grc/risk-register/threats";

export const GetThreats = (
  pageNumber?: number,
  tags?: string[] | undefined,
  groups?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-threats", pageNumber, tags, groups],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/getall`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddThreat = () =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(`/api/${apiVersion}/${prefix}`, info, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-threats"]),
    }
  );

export const EditThreat = (threatID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${threatID}`,
          info,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-threat-metadata"]);
      },
    }
  );

export const GetThreatMetadata = (threatID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-threat-metadata", threatID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${threatID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: threatID !== "",
      keepPreviousData: false,
    }
  );
