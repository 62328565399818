import React from "react";
import {
  convertToUTCShortString,
  getCustomerID,
  getEmailFromID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import UpdateEntity from "./UpdateEntity";
import EntityTags from "./EntityTags";
import { GetEntityMetadata } from "../../../../../services/erc/entities/entities";
import ArchiveRestoreEntity from "./ArchiveRestoreEntity";
import { GetAllUsers } from "src/services/settings/users";
import { GetEntityTypeMetadata } from "src/services/erc/entities/entity-types";

const EntityMetadata = ({ entityID }: { entityID: string }) => {
  const customerID = getCustomerID();

  const { data: entityMetadata } = GetEntityMetadata(entityID);
  const { data: entityTypeMetadata } = GetEntityTypeMetadata(
    entityMetadata?.entity_type_id || ""
  );
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {entityMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{entityMetadata.name}</h2>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    created by{" "}
                    {getEmailFromID(allUsers, entityMetadata.created_by)}
                  </h4>
                  <span>
                    at {convertToUTCShortString(entityMetadata.created_at)}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    last updated by{" "}
                    {getEmailFromID(allUsers, entityMetadata.last_updated_by)}
                  </h4>
                  <span>
                    at {convertToUTCShortString(entityMetadata.last_updated_at)}
                  </span>
                </article>
              </article>
              <article className="flex items-center gap-5">
                <UpdateEntity entityID={entityID} entity={entityMetadata} />
                <ArchiveRestoreEntity
                  entityID={entityID}
                  entity={entityMetadata}
                />
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-5 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[entityMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[entityMetadata.status.toLowerCase()]
                  }`}
                >
                  {entityMetadata.status}
                </span>
              </article>
            </section>
            {entityTypeMetadata?.name && (
              <article className="grid content-start gap-1">
                <h4 className="dark:text-gray-300 text-b2-reg">Entity Type</h4>
                <span className="text-b1-semi">{entityTypeMetadata.name}</span>
              </article>
            )}
            {entityMetadata.external_reference_id && (
              <article className="grid content-start gap-1">
                <h4 className="dark:text-gray-300 text-b2-reg">
                  External Reference Id
                </h4>
                <span className="text-b1-semi">
                  {entityMetadata.external_reference_id}
                </span>
              </article>
            )}
          </section>
          <EntityTags entityID={entityID} tags={entityMetadata.tags} />
        </header>
      )}
    </>
  );
};

export default EntityMetadata;
