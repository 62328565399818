import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "src/components/Input/CategoryInput";
import { EditVulnerability } from "src/services/erc/risks/threats-vulns/vulns";

const UpdateVuln = ({ vulnID, vuln }: { vulnID: string; vuln: any }) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: vuln.name,
    description: vuln.description,
    category: vuln.category,
    sub_category: vuln.sub_category,
    status: vuln.status,
  });
  const [valid, setValid] = useState<boolean>(true);

  const editRisk = EditVulnerability(vulnID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="px-4 py-2 text-b1-reg dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-xl"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Edit Vulnerability
          </h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <RegularInput
              label="Category"
              keyName="category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Subcategory"
              keyName="sub_category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editRisk.mutate({
                  info: inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateVuln;
