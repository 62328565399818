import React from "react";
import {
  convertToUTCShortString,
  getCustomerID,
  getEmailFromID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetAllUsers } from "src/services/settings/users";
import { GetVulnerabilityMetadata } from "src/services/erc/risks/threats-vulns/vulns";
import UpdateVuln from "./UpdateVuln";
import MetadataField from "src/components/ERC/MetadataField";

const ThreatMetadata = ({ vulnID }: { vulnID: string }) => {
  const customerID = getCustomerID();

  const { data: vulnMetadata } = GetVulnerabilityMetadata(vulnID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {vulnMetadata && (
        <header className="grid gap-5">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{vulnMetadata.name}</h2>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    created by{" "}
                    {getEmailFromID(allUsers, vulnMetadata.created_by)}
                  </h4>
                  <span>
                    at {convertToUTCShortString(vulnMetadata.created_at)}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    last updated by{" "}
                    {getEmailFromID(allUsers, vulnMetadata.last_updated_by)}
                  </h4>
                  <span>
                    at {convertToUTCShortString(vulnMetadata.last_updated_at)}
                  </span>
                </article>
              </article>
              <UpdateVuln vulnID={vulnID} vuln={vulnMetadata} />
            </header>
            <section className="flex flex-wrap items-center gap-5 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[vulnMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[vulnMetadata.status.toLowerCase()]
                  }`}
                >
                  {vulnMetadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={vulnMetadata}
              label="Description"
              field="description"
            />
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {vulnMetadata.category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Category</h4>
                  <span className="text-b1-semi">{vulnMetadata.category}</span>
                </article>
              )}
              {vulnMetadata.sub_category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Subcategory
                  </h4>
                  <span className="text-b1-semi">
                    {vulnMetadata.sub_category}
                  </span>
                </article>
              )}
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default ThreatMetadata;
