import React, { useState } from "react";

const MetadataField = ({
  metadata,
  label,
  field,
}: {
  metadata: any;
  label: string;
  field: string;
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <>
      {metadata[field] && (
        <article className="grid content-start gap-1">
          <h4 className="dark:text-gray-300 text-b2-reg">{label}</h4>
          <article className="flex flex-wrap items-center gap-2 break-all text-b1-semi">
            {metadata[field].slice(0, showMore ? metadata[field].length : 200)}
            {metadata[field].length > 200 && !showMore && "..."}
            {metadata[field].length > 200 && (
              <button
                onClick={() => setShowMore(!showMore)}
                className="dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
              >
                show {showMore ? "less" : "more"}
              </button>
            )}
          </article>
        </article>
      )}
    </>
  );
};

export default MetadataField;
