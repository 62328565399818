import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "../../../../components/Input/CategoryInput";
import { AddEntity } from "../../../../services/erc/entities/entities";
import ToastLayout from "../../../../layouts/ToastLayout";
import UserInput from "src/components/Input/UserInput";
import EntityTypeFilter from "src/components/Filter/ERC/EntityTypeFilter";

const NewEntity = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    status: "",
    owner_id: "",
    external_reference_id: "",
    entity_type: "",
  });
  const [valid, setValid] = useState<boolean>(true);

  const addEntity = AddEntity();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addEntity.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            status: "",
            owner_id: "",
            external_reference_id: "",
            entity_type: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Entity</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Entity</h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <UserInput
              label="Owner"
              keyName="owner_id"
              inputs={inputs}
              setInputs={setInputs}
              vertical
              required
            />
            <RegularInput
              label="External Reference Id"
              keyName="external_reference_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <EntityTypeFilter
              label="Entity Type"
              keyName="entity_type"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
          </section>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === "" || inputs.entity_type === ""}
              className="blue-button"
              onClick={() => {
                addEntity.mutate({
                  info: inputs,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addEntity.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New entity has been created"
      />
    </>
  );
};

export default NewEntity;
