export const riskScores = {
  0: "SEVERE",
  6: "SIGNIFICANT",
  12: "MODERATE",
  18: "MINOR",
  24: "NEGLIGIBLE",
  31: "VERY_UNLIKELY",
  32: "UNLIKELY",
  33: "POSSIBLE",
  34: "LIKELY",
  35: "VERY_LIKELY",
};
export const riskScoreMappings = {
  1: ["SEVERE", "VERY_UNLIKELY"],
  2: ["SEVERE", "UNLIKELY"],
  3: ["SEVERE", "POSSIBLE"],
  4: ["SEVERE", "LIKELY"],
  5: ["SEVERE", "VERY_LIKELY"],
  7: ["SIGNIFICANT", "VERY_UNLIKELY"],
  8: ["SIGNIFICANT", "UNLIKELY"],
  9: ["SIGNIFICANT", "POSSIBLE"],
  10: ["SIGNIFICANT", "LIKELY"],
  11: ["SIGNIFICANT", "VERY_LIKELY"],
  13: ["MODERATE", "VERY_UNLIKELY"],
  14: ["MODERATE", "UNLIKELY"],
  15: ["MODERATE", "POSSIBLE"],
  16: ["MODERATE", "LIKELY"],
  17: ["MODERATE", "VERY_LIKELY"],
  19: ["MINOR", "VERY_UNLIKELY"],
  20: ["MINOR", "UNLIKELY"],
  21: ["MINOR", "POSSIBLE"],
  22: ["MINOR", "LIKELY"],
  23: ["MINOR", "VERY_LIKELY"],
  25: ["NEGLIGIBLE", "VERY_UNLIKELY"],
  26: ["NEGLIGIBLE", "UNLIKELY"],
  27: ["NEGLIGIBLE", "POSSIBLE"],
  28: ["NEGLIGIBLE", "LIKELY"],
  29: ["NEGLIGIBLE", "VERY_LIKELY"],
};
export const riskScoreColors = {
  1: "dark:bg-yellow-500 dark:hover:bg-yellow-400/70 duration-100",
  2: "dark:bg-orange-500 dark:hover:bg-orange-400/70 duration-100",
  3: "dark:bg-orange-500 dark:hover:bg-orange-400/70 duration-100",
  4: "dark:bg-red-500 dark:hover:bg-red-400/70 duration-100",
  5: "dark:bg-red-500 dark:hover:bg-red-400/70 duration-100",
  7: "dark:bg-yellow-500 dark:hover:bg-yellow-400/70 duration-100",
  8: "dark:bg-yellow-500 dark:hover:bg-yellow-400/70 duration-100",
  9: "dark:bg-orange-500 dark:hover:bg-orange-400/70 duration-100",
  10: "dark:bg-orange-500 dark:hover:bg-orange-400/70 duration-100",
  11: "dark:bg-red-500 dark:hover:bg-red-400/70 duration-100",
  13: "dark:bg-green-400 dark:hover:bg-green-300/70 duration-100",
  14: "dark:bg-green-400 dark:hover:bg-green-300/70 duration-100",
  15: "dark:bg-yellow-500 dark:hover:bg-yellow-400/70 duration-100",
  16: "dark:bg-yellow-500 dark:hover:bg-yellow-400/70 duration-100",
  17: "dark:bg-orange-500 dark:hover:bg-orange-400/70 duration-100",
  19: "dark:bg-green-500 dark:hover:bg-green-400/70 duration-100",
  20: "dark:bg-green-400 dark:hover:bg-green-300/70 duration-100",
  21: "dark:bg-green-400 dark:hover:bg-green-300/70 duration-100",
  22: "dark:bg-green-400 dark:hover:bg-green-300/70 duration-100",
  23: "dark:bg-yellow-500 dark:hover:bg-yellow-400/70 duration-100",
  25: "dark:bg-green-500 dark:hover:bg-green-400/70 duration-100",
  26: "dark:bg-green-500 dark:hover:bg-green-400/70 duration-100",
  27: "dark:bg-green-500 dark:hover:bg-green-400/70 duration-100",
  28: "dark:bg-green-500 dark:hover:bg-green-400/70 duration-100",
  29: "dark:bg-green-400 dark:hover:bg-green-300/70 duration-100",
};
