import React from "react";
import { convertToUTCShortString, getEmailFromID } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import UpdateRisk from "./UpdateRisk";
import { GetRiskMetadata } from "../../../../../services/erc/risks/risks";
import { GetAllUsers } from "../../../../../services/settings/users";
import { getCustomerID } from "../../../../../utils/general";
import RiskTags from "./RiskTags";
import { KeyStringVal } from "src/types/general";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import MetadataField from "src/components/ERC/MetadataField";

const RiskMetadata = ({ riskID }: { riskID: string }) => {
  const customerID = getCustomerID();

  const { data: riskMetadata } = GetRiskMetadata(riskID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {riskMetadata && (
        <header className="grid gap-5">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{riskMetadata.name}</h2>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    created by{" "}
                    {getEmailFromID(allUsers, riskMetadata.created_by)}
                  </h4>
                  <span>
                    at {convertToUTCShortString(riskMetadata.created_at)}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    last updated by{" "}
                    {getEmailFromID(allUsers, riskMetadata.last_updated_by)}
                  </h4>
                  <span>
                    at {convertToUTCShortString(riskMetadata.last_updated_at)}
                  </span>
                </article>
              </article>
              {/* <UpdateRisk riskID={riskID} risk={riskMetadata} /> */}
            </header>
            <section className="flex flex-wrap items-center gap-5 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[riskMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[riskMetadata.status.toLowerCase()]
                  }`}
                >
                  {riskMetadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={riskMetadata}
              label="Description"
              field="description"
            />
            <section className="grid md:grid-cols-3 gap-5">
              {riskMetadata.type && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Type</h4>
                  <span className="text-b1-semi">{riskMetadata.type}</span>
                </article>
              )}
              {riskMetadata.classification && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Classification
                  </h4>
                  <span className="text-b1-semi">
                    {riskMetadata.classification}
                  </span>
                </article>
              )}
              {riskMetadata.category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Category</h4>
                  <span className="text-b1-semi">{riskMetadata.category}</span>
                </article>
              )}
              {riskMetadata.sub_category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Subcategory
                  </h4>
                  <span className="text-b1-semi">
                    {riskMetadata.sub_category}
                  </span>
                </article>
              )}
              {riskMetadata.threat && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Threat</h4>
                  <span className="text-b1-semi">{riskMetadata.threat}</span>
                </article>
              )}
              {riskMetadata.vulnerability && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Vulnerability
                  </h4>
                  <span className="text-b1-semi">
                    {riskMetadata.vulnerability}
                  </span>
                </article>
              )}
              {riskMetadata.risk_identification_source && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Risk Identification Source
                  </h4>
                  <span className="text-b1-semi">
                    {riskMetadata.risk_identification_source}
                  </span>
                </article>
              )}
            </section>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Risk Treatment</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      {riskMetadata.treatment_type && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Treatment Type
                          </h4>
                          <span className="text-b1-semi">
                            {riskMetadata.treatment_type}
                          </span>
                        </article>
                      )}
                      {riskMetadata.treatment_status && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Treatment Status
                          </h4>
                          <span className="text-b1-semi">
                            {riskMetadata.treatment_status}
                          </span>
                        </article>
                      )}
                      {riskMetadata.deadline && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Deadline
                          </h4>
                          <span className="text-b1-semi">
                            {convertToUTCShortString(riskMetadata.deadline)}
                          </span>
                        </article>
                      )}
                      {riskMetadata.control_type && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Control Type
                          </h4>
                          <span className="text-b1-semi">
                            {riskMetadata.control_type}
                          </span>
                        </article>
                      )}
                      {riskMetadata.risk_acceptance_via_email && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Risk Acceptance via Email
                          </h4>
                          <span className="text-b1-semi">
                            {riskMetadata.risk_acceptance_via_email}
                          </span>
                        </article>
                      )}
                      {riskMetadata.cost_benefit_analyis && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Cost / Benefit Analysis
                          </h4>
                          <span className="text-b1-semi">
                            {riskMetadata.cost_benefit_analyis}
                          </span>
                        </article>
                      )}
                      {riskMetadata.treatment_plan && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Treatment Plan
                          </h4>
                          <span className="text-b1-semi">
                            {riskMetadata.treatment_plan}
                          </span>
                        </article>
                      )}
                      {riskMetadata.attestation && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Attestation
                          </h4>
                          <span className="text-b1-semi">
                            {riskMetadata.attestation}
                          </span>
                        </article>
                      )}
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Owners, Reviewers, & Approvers
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      {riskMetadata.owner_same_as_entity_owner && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Owner Same as Entity Owner
                          </h4>
                          <span className="text-b1-semi">True</span>
                        </article>
                      )}
                      {riskMetadata.owners && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Owners
                          </h4>
                          <article>
                            {riskMetadata.owners.map((approverID: string) => {
                              const owner =
                                allUsers?.find(
                                  (user: KeyStringVal) =>
                                    user.user_id === approverID
                                )?.user_email || "";

                              return (
                                <span key={approverID} className="text-b1-semi">
                                  {owner}
                                </span>
                              );
                            })}
                          </article>
                        </article>
                      )}
                      {riskMetadata.reviewers && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Reviewers
                          </h4>
                          <article>
                            {riskMetadata.reviewers.map(
                              (reviewerID: string) => {
                                const reviewer =
                                  allUsers?.find(
                                    (user: KeyStringVal) =>
                                      user.user_id === reviewerID
                                  )?.user_email || "";

                                return (
                                  <span
                                    key={reviewerID}
                                    className="text-b1-semi"
                                  >
                                    {reviewer}
                                  </span>
                                );
                              }
                            )}
                          </article>
                        </article>
                      )}
                      {riskMetadata.approvers && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Approvers
                          </h4>
                          <article>
                            {riskMetadata.approvers.map(
                              (approverID: string) => {
                                const approver =
                                  allUsers?.find(
                                    (user: KeyStringVal) =>
                                      user.user_id === approverID
                                  )?.user_email || "";

                                return (
                                  <span
                                    key={approverID}
                                    className="text-b1-semi"
                                  >
                                    {approver}
                                  </span>
                                );
                              }
                            )}
                          </article>
                        </article>
                      )}
                      {riskMetadata.create_risks_automatically && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Create Risks Automatically
                          </h4>
                          <span className="text-b1-semi">True</span>
                        </article>
                      )}
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
          <RiskTags riskID={riskID} tags={riskMetadata.tags} />
        </header>
      )}
    </>
  );
};

export default RiskMetadata;
