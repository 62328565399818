import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "../../../../components/Input/CategoryInput";
import { AddRisk } from "../../../../services/erc/risks/risks";
import ToastLayout from "../../../../layouts/ToastLayout";
import MultiUserInput from "src/components/Input/MultiUserInput";
import CheckboxInput from "src/components/Input/CheckboxInput";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import RiskBlueprintFilter from "src/components/Filter/ERC/RiskBlueprintFilter";
import { Disclosure } from "@headlessui/react";

const NewRisk = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    classification: "",
    description: "",
    threat: "",
    vulnerability: "",
    category: "",
    sub_category: "",
    attestation: "",
    type: "",
    owner_same_as_entity_owner: true,
    owners: [],
    reviewers: [],
    approvers: [],
    treatment: "",
    treatment_plan: "",
    deadline: Date.now(),
    create_risks_automatically: true,
    inherent_risk_score: 0,
    inherent_risk_impact_level: "Moderate",
    inherent_risk_likelihood_level: "",
    assessment: "",
    additional_information: {},
    treatment_type: "MITIGATE",
    treatment_status: "",
    status: "DRAFT",
    risk_identification_source: "",
    control_type: "",
    risk_acceptance_via_email: "",
    cost_benefit_analyis: "",
  });
  const [valid, setValid] = useState<boolean>(true);

  const addRisk = AddRisk();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addRisk.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
            threat: "",
            vulnerability: "",
            category: "",
            sub_category: "",
            attestation: "",
            type: "",
            owner_same_as_entity_owner: true,
            owners: [],
            reviewers: [],
            approvers: [],
            treatment: "",
            treatment_plan: "",
            deadline: Date.now(),
            create_risks_automatically: true,
            inherent_risk_score: 0,
            inherent_risk_impact_level: "MODERATE",
            inherent_risk_likelihood_level: "POSSIBLE",
            assessment: "",
            additional_information: {},
            treatment_type: "MITIGATE",
            treatment_status: "",
            status: "DRAFT",
            risk_identification_source: "",
            control_type: "",
            risk_acceptance_via_email: "",
            cost_benefit_analyis: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Risk</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Risk</h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <RiskBlueprintFilter
              label="Risk Blueprint"
              keyName="risk_blueprint_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Type"
              keyName="type"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Classification"
              keyName="classification"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Category"
              keyName="category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Subcategory"
              keyName="sub_category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Threat"
              keyName="threat"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Vulnerability"
              keyName="vulnerability"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Risk Identification Source"
              keyName="risk_identification_source"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Description"
              keyName="description"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
          </section>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-subt1-semi">Risk Score</h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-5">
                    <CategoryInput
                      label="Inherent Risk Impact Level"
                      keyName="inherent_risk_impact_level"
                      list={[
                        "NEGLIGIBLE",
                        "MINOR",
                        "MODERATE",
                        "SIGNIFICANT",
                        "SEVERE",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Inherent Risk Likelihood Level"
                      keyName="inherent_risk_likelihood_level"
                      list={[
                        "VERY_UNLIKELY",
                        "UNLIKELY",
                        "POSSIBLE",
                        "LIKELY",
                        "VERY_LIKELY",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Residual Risk Impact Level"
                      keyName="residual_risk_impact_level"
                      list={[
                        "NEGLIGIBLE",
                        "MINOR",
                        "MODERATE",
                        "SIGNIFICANT",
                        "SEVERE",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Residual Risk Likelihood Level"
                      keyName="residual_risk_likelihood_level"
                      list={[
                        "VERY_UNLIKELY",
                        "UNLIKELY",
                        "POSSIBLE",
                        "LIKELY",
                        "VERY_LIKELY",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-subt1-semi">Risk Treatment</h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-5">
                    <CategoryInput
                      label="Treatment Type"
                      keyName="treatment_type"
                      list={["ACCEPT", "AVOID", "MITIGATE", "TRANSFER"]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Treatment Status"
                      keyName="treatment_status"
                      list={["Open", "Closed", "Resolved", "Pending"]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <GeneralSnapshotDatepicker
                      label="Deadline"
                      keyName="deadline"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    <CategoryInput
                      label="Control Type"
                      keyName="control_type"
                      list={["Corrective", "Detective", "Preventative"]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <RegularInput
                      label="Risk Acceptance via Email"
                      keyName="risk_acceptance_via_email"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    <RegularInput
                      label="Cost / Benefit Analysis"
                      keyName="cost_benefit_analyis"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    <RegularInput
                      label="Treatment Plan"
                      keyName="treatment_plan"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                    <CategoryInput
                      label="Attestation"
                      keyName="attestation"
                      list={["DRAFT", "ATTEST", "REVIEW", "MONITOR", "RETIRED"]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-subt1-semi">
                    Owners, Reviewers, & Approvers
                  </h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-5">
                    <CheckboxInput
                      label="Owner Same as Entity Owner"
                      keyName="owner_same_as_entity_owner"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    {!inputs.owner_same_as_entity_owner && (
                      <MultiUserInput
                        label="Owners"
                        keyName="owners"
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                    )}
                    <MultiUserInput
                      label="Reviewers"
                      keyName="reviewers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <MultiUserInput
                      label="Approvers"
                      keyName="approvers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CheckboxInput
                      label="Create Risks Automatically"
                      keyName="create_risks_automatically"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                addRisk.mutate({
                  info: inputs,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addRisk.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New risk has been created"
      />
    </>
  );
};

export default NewRisk;
