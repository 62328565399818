/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import {
  riskScoreColors,
  riskScoreMappings,
  riskScores,
} from "src/constants/erc";
import ModalLayout from "src/layouts/ModalLayout";
import { EditRisk, GetRiskMetadata } from "src/services/erc/risks/risks";

const RiskScore = ({ riskID }: { riskID: string }) => {
  const [show, setShow] = useState<string>("");

  const { data: riskMetadata } = GetRiskMetadata(riskID);
  const editRisk = EditRisk(riskID);

  const handleOnClose = () => setShow(-1);

  return (
    <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
      {riskMetadata && (
        <section className="grid lg:grid-cols-2 gap-10">
          <section className="flex flex-col flex-grow gap-5">
            <h4 className="text-center text-t1-semi">Inherent Risk Score</h4>
            <section className="grid grid-cols-6 grid-rows-6">
              {[...Array(36).keys()].map((idx) => {
                const selected =
                  riskScoreMappings[idx] &&
                  riskMetadata.inherent_risk_impact_level ===
                    riskScoreMappings[idx][0] &&
                  riskMetadata.inherent_risk_likelihood_level ===
                    riskScoreMappings[idx][1];
                return (
                  <Fragment key={idx}>
                    <button
                      disabled={idx % 6 === 0 || idx >= 30}
                      className={`place-content-center w-full h-20 text-center ${
                        idx % 6 === 0 || idx >= 30
                          ? ""
                          : selected
                          ? "dark:bg-blue-600"
                          : riskScoreColors[idx]
                      } ${
                        idx % 6 === 0 || idx >= 30
                          ? ""
                          : "border-1 dark:border-gray-900"
                      }`}
                      onClick={() => setShow(`inherent-${idx}`)}
                    >
                      {selected && (
                        <article className="grid gap-1">
                          <span>{riskScoreMappings[idx][0]}</span>
                          <span>{riskScoreMappings[idx][1]}</span>
                        </article>
                      )}
                      {idx !== 30 && riskScores[idx]?.replaceAll("_", " ")}
                    </button>
                    <ModalLayout
                      showModal={show === `inherent-${idx}`}
                      onClose={handleOnClose}
                    >
                      <section className="grid gap-10">
                        <h2 className="text-subt1-semi">Confirmation</h2>
                        <h4 className="text-b1-reg">
                          Are you sure you want to change this risk score?
                        </h4>
                        <article className="flex items-center place-content-end gap-5">
                          <button
                            className="black-button"
                            onClick={handleOnClose}
                          >
                            Cancel
                          </button>
                          <button
                            className="blue-button"
                            onClick={() => {
                              editRisk.mutate({
                                info: {
                                  ...riskMetadata,
                                  inherent_risk_impact_level:
                                    riskScoreMappings[idx][0],
                                  inherent_risk_likelihood_level:
                                    riskScoreMappings[idx][1],
                                },
                              });
                              handleOnClose();
                            }}
                          >
                            Done
                          </button>
                        </article>
                      </section>
                    </ModalLayout>
                  </Fragment>
                );
              })}
            </section>
          </section>
          <section className="flex flex-col flex-grow gap-5">
            <h4 className="text-center text-t1-semi">Residual Risk Score</h4>
            <section className="grid grid-cols-6 grid-rows-6">
              {[...Array(36).keys()].map((idx) => {
                const selected =
                  riskScoreMappings[idx] &&
                  riskMetadata.residual_risk_impact_level ===
                    riskScoreMappings[idx][0] &&
                  riskMetadata.residual_risk_likelihood_level ===
                    riskScoreMappings[idx][1];
                return (
                  <Fragment key={idx}>
                    <button
                      disabled={idx % 6 === 0 || idx >= 30}
                      className={`place-content-center w-full h-20 text-center ${
                        idx % 6 === 0 || idx >= 30
                          ? ""
                          : selected
                          ? "dark:bg-blue-600"
                          : riskScoreColors[idx]
                      } ${
                        idx % 6 === 0 || idx >= 30
                          ? ""
                          : "border-1 dark:border-gray-900"
                      }`}
                      onClick={() => setShow(`residual-${idx}`)}
                    >
                      {selected && (
                        <article className="grid gap-1">
                          <span>{riskScoreMappings[idx][0]}</span>
                          <span>{riskScoreMappings[idx][1]}</span>
                        </article>
                      )}
                      {idx !== 30 && riskScores[idx]?.replaceAll("_", " ")}
                    </button>
                    <ModalLayout
                      showModal={show === `residual-${idx}`}
                      onClose={handleOnClose}
                    >
                      <section className="grid gap-10">
                        <h2 className="text-subt1-semi">Confirmation</h2>
                        <h4 className="text-b1-reg">
                          Are you sure you want to change this risk score?
                        </h4>
                        <article className="flex items-center place-content-end gap-5">
                          <button
                            className="black-button"
                            onClick={handleOnClose}
                          >
                            Cancel
                          </button>
                          <button
                            className="blue-button"
                            onClick={() => {
                              editRisk.mutate({
                                info: {
                                  ...riskMetadata,
                                  residual_risk_impact_level:
                                    riskScoreMappings[idx][0],
                                  residual_risk_likelihood_level:
                                    riskScoreMappings[idx][1],
                                },
                              });
                              handleOnClose();
                            }}
                          >
                            Done
                          </button>
                        </article>
                      </section>
                    </ModalLayout>
                  </Fragment>
                );
              })}
            </section>
          </section>
        </section>
      )}
    </section>
  );
};

export default RiskScore;
