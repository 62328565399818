import React from "react";
import {
  convertToUTCShortString,
  getCustomerID,
  getEmailFromID,
} from "src/utils/general";
import { GetRiskBlueprintMetadata } from "../../../../../services/erc/risks/risk-blueprints";
import ArchiveRestoreRiskBlueprint from "./ArchiveRestoreRiskBlueprint";
import UpdateRiskBlueprint from "./UpdateRiskBlueprint";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetAllUsers } from "src/services/settings/users";
import { KeyStringVal } from "src/types/general";
import MetadataField from "src/components/ERC/MetadataField";

const RiskBlueprintMetadata = ({
  riskBlueprintID,
}: {
  riskBlueprintID: string;
}) => {
  const customerID = getCustomerID();

  const { data: riskBlueprintMetadata } =
    GetRiskBlueprintMetadata(riskBlueprintID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {riskBlueprintMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">
                  {riskBlueprintMetadata.name}
                </h2>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    created by{" "}
                    {getEmailFromID(allUsers, riskBlueprintMetadata.created_by)}
                  </h4>
                  <span>
                    at{" "}
                    {convertToUTCShortString(riskBlueprintMetadata.created_at)}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    last updated by{" "}
                    {getEmailFromID(
                      allUsers,
                      riskBlueprintMetadata.last_updated_by
                    )}
                  </h4>
                  <span>
                    at{" "}
                    {convertToUTCShortString(
                      riskBlueprintMetadata.last_updated_at
                    )}
                  </span>
                </article>
              </article>
              <article className="flex items-center gap-5">
                <UpdateRiskBlueprint
                  riskBlueprintID={riskBlueprintID}
                  blueprint={riskBlueprintMetadata}
                />
                <ArchiveRestoreRiskBlueprint
                  riskBlueprintID={riskBlueprintID}
                  blueprint={riskBlueprintMetadata}
                />
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-5 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[riskBlueprintMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[
                      riskBlueprintMetadata.status.toLowerCase()
                    ]
                  }`}
                >
                  {riskBlueprintMetadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={riskBlueprintMetadata}
              label="Description"
              field="description"
            />
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {riskBlueprintMetadata.type && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Type</h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.type}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.classification && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Classification
                  </h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.classification}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.source_name && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Source Name
                  </h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.source_name}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.source_id && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Source Id</h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.source_id}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Category</h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.category}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.sub_category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Subcategory
                  </h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.sub_category}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.threat && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Threat</h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.threat}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.vulnerability && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Vulnerability
                  </h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.vulnerability}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.owners && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Owners</h4>
                  <article>
                    {riskBlueprintMetadata.owners.map((approverID: string) => {
                      const owner =
                        allUsers?.find(
                          (user: KeyStringVal) => user.user_id === approverID
                        )?.user_email || "";

                      return (
                        <span key={approverID} className="text-b1-semi">
                          {owner}
                        </span>
                      );
                    })}
                  </article>
                </article>
              )}
              {riskBlueprintMetadata.reviewers && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Reviewers</h4>
                  <article>
                    {riskBlueprintMetadata.reviewers.map(
                      (reviewerID: string) => {
                        const reviewer =
                          allUsers?.find(
                            (user: KeyStringVal) => user.user_id === reviewerID
                          )?.user_email || "";

                        return (
                          <span key={reviewerID} className="text-b1-semi">
                            {reviewer}
                          </span>
                        );
                      }
                    )}
                  </article>
                </article>
              )}
              {riskBlueprintMetadata.approvers && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Approvers</h4>
                  <article>
                    {riskBlueprintMetadata.approvers.map(
                      (approverID: string) => {
                        const approver =
                          allUsers?.find(
                            (user: KeyStringVal) => user.user_id === approverID
                          )?.user_email || "";

                        return (
                          <span key={approverID} className="text-b1-semi">
                            {approver}
                          </span>
                        );
                      }
                    )}
                  </article>
                </article>
              )}
              {riskBlueprintMetadata.treatment_type && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Treatment Type
                  </h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.treatment_type}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.treatment_plan && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Treatment Plan
                  </h4>
                  <span className="text-b1-semi">
                    {riskBlueprintMetadata.treatment_plan}
                  </span>
                </article>
              )}
              {riskBlueprintMetadata.owner_same_as_entity_owner && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Owner Same as Entity Owner
                  </h4>
                  <span className="text-b1-semi">True</span>
                </article>
              )}
              {riskBlueprintMetadata.create_risks_automatically && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Create Risks Automatically
                  </h4>
                  <span className="text-b1-semi">True</span>
                </article>
              )}
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default RiskBlueprintMetadata;
