/* eslint-disable no-restricted-globals */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import { AddVendorToGroup } from "src/services/third-party-risk/vendors/vendor-groups";
import { GetVendorsAndPartners } from "../../../services/third-party-risk/vendors/vendors";
import { RemoveVendorFromGroup } from "../../../services/third-party-risk/vendors/vendor-groups";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useState } from "react";

const VendorsFilter = ({
  label,
  members,
  groupID,
}: {
  label?: string;
  members: KeyStringVal[];
  groupID: string;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: vendors } = GetVendorsAndPartners(1);
  const addVendorToGroup = AddVendorToGroup();
  const removeVendorToGroup = RemoveVendorFromGroup();

  const filteredItems = vendors?.data?.filter((item: KeyStringVal) =>
    item.name
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  return (
    <article className="flex items-center gap-2 text-b2-reg">
      <h4 className="w-max">{label}</h4>
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-2 w-[15rem] h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder={`${members?.length || 0} selected`}
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 dark:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-8 left-0 w-full max-h-[30rem] dark:bg-gray-900 overflow-auto scrollbar z-10">
            {filteredItems?.map((vendor: KeyStringVal, index: number) => {
              const included = members?.some(
                (member) => member.third_party_id === vendor.third_party_id
              );
              if (vendor.group_id && vendor.group_id !== groupID) return null;

              return (
                <button
                  key={index}
                  className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:disabled:hover:bg-transparent dark:hover:bg-gray-600/70 duration-100"
                  onClick={() => {
                    if (included)
                      removeVendorToGroup.mutate({
                        groupID: sessionStorage.vendor_group_id,
                        vendor: [vendor.third_party_id],
                      });
                    else
                      addVendorToGroup.mutate({
                        groupID: sessionStorage.vendor_group_id,
                        vendor: [vendor.third_party_id],
                      });
                  }}
                >
                  {included && <FontAwesomeIcon icon={faCheck} />}
                  <p>{vendor.name}</p>
                </button>
              );
            })}
          </article>
        )}
      </article>
    </article>
  );
};

export default VendorsFilter;
