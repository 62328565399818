/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import RiskList from "./RiskList/RiskList";
import NewRisk from "./RiskList/NewRisk";
import NewRiskBlueprint from "./RiskBlueprints/NewRiskBlueprint";
import RiskBlueprints from "./RiskBlueprints/RiskBlueprints";
import Assessments from "./Assessments/Assessments";
import ThreatsVulns from "./ThreatsVulns/ThreatsVulns";

const Risks = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
}) => {
  useEffect(() => {
    setSelectedTab("risk blueprints");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {[
            "risk blueprints",
            "risks",
            "threats & vulnerabilities",
            "assessments",
          ].map((tab) => {
            return (
              <button
                key={tab}
                className={`flex items-center gap-2 px-8 py-2 text-center capitalize ${
                  selectedTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
                {tab === "assessments" && (
                  <img src="/general/beta.svg" alt="beta" />
                )}
              </button>
            );
          })}
        </nav>
        {selectedTab === "risk blueprints" ? (
          <NewRiskBlueprint />
        ) : selectedTab === "risks" ? (
          <NewRisk />
        ) : null}
      </header>
      {selectedTab === "risks" ? (
        <RiskList />
      ) : selectedTab === "risk blueprints" ? (
        <RiskBlueprints />
      ) : selectedTab === "threats & vulnerabilities" ? (
        <ThreatsVulns />
      ) : (
        <Assessments />
      )}
    </section>
  );
};

export default Risks;
