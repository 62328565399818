import React, { useState } from "react";
import Threats from "./Threats/Threats";
import Vulns from "./Vulns/Vulns";
import NewThreat from "./Threats/NewThreat";
import NewVuln from "./Vulns/NewVuln";

const ThreatsVulns = () => {
  const [selectedTab, setSelectedTab] = useState<string>("threats");
  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {["threats", "vulnerabilities"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "threats" ? <NewThreat /> : <NewVuln />}
      </header>
      {selectedTab === "threats" ? <Threats /> : <Vulns />}
    </section>
  );
};

export default ThreatsVulns;
