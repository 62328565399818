import React, { useState } from "react";
import { convertToUTCShortString, getEmailFromID } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetVendorMetadata } from "src/services/third-party-risk/vendors/vendors";
import UpdateVendor from "../UpdateVendor";
import VendorTags from "../VendorTags";
import { GetAllUsers } from "src/services/settings/users";
import { getCustomerID } from "../../../../../utils/general";
import VendorGroupFilter from "../../../../../components/Filter/ThirdPartyRisk/VendorGroupFilter";
import Status from "./Status";
import SendEmail from "./SendEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { Disclosure } from "@headlessui/react";
import ExportResponses from "./DocumentList/VendorResponseList/ExportResponses";
import MetadataField from "src/components/ERC/MetadataField";

const VendorMetadata = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string | undefined;
}) => {
  const customerID = getCustomerID();

  const hasAssessment = localStorage.assessmentCycle && assessmentID;

  const { data: vendorMetadata } = GetVendorMetadata(vendorID, assessmentID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {vendorMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            {hasAssessment && (
              <h4>Assessment: {localStorage.assessmentCycle}</h4>
            )}
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <article className="flex items-center gap-5">
                  {vendorMetadata.logo_uri && (
                    <img
                      src={vendorMetadata.logo_uri}
                      alt="logo"
                      className="w-10 h-10 rounded-full"
                    />
                  )}
                  <h2 className="text-subt1-semi">{vendorMetadata.name}</h2>
                  <VendorGroupFilter
                    label="Vendor Group"
                    vendor={vendorMetadata}
                  />
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>last update at</h4>
                  <span>
                    {convertToUTCShortString(vendorMetadata.last_updated)}
                  </span>
                </article>
              </article>
              <article className="flex items-center gap-5">
                {hasAssessment && vendorMetadata.expiry_time >= 0 && (
                  <SendEmail
                    vendorID={vendorID}
                    assessmentID={assessmentID}
                    vendor={vendorMetadata}
                  />
                )}
                <Status vendorID={vendorID} active={vendorMetadata.active} />
                {hasAssessment && (
                  <ExportResponses
                    vendorID={vendorID}
                    assessmentID={assessmentID}
                  />
                )}
                <UpdateVendor vendorID={vendorID} vendor={vendorMetadata} />
              </article>
            </header>
            <section className="flex flex-wrap items-center justify-between gap-10 text-b1-reg">
              <article className="flex flex-wrap items-center gap-2">
                <article
                  className={`flex items-center gap-1 ${
                    attributeColors[
                      vendorMetadata.active ? "active" : "inactive"
                    ]
                  }`}
                >
                  Status
                  <span
                    className={`capitalize ${
                      attributeTextColors[
                        vendorMetadata.active ? "active" : "inactive"
                      ]
                    }`}
                  >
                    {vendorMetadata.active ? "active" : "inactive"}
                  </span>
                </article>
              </article>
              {vendorMetadata.email_sent && vendorMetadata.expiry_time >= 0 && (
                <span>
                  <FontAwesomeIcon
                    icon={faWarning}
                    className="text-yellow-500"
                  />{" "}
                  {vendorMetadata.expiry_time <= 24
                    ? `${vendorMetadata.expiry_time} hours`
                    : `${Math.round(
                        vendorMetadata.expiry_time / 24
                      )} days`}{" "}
                  left for response
                </span>
              )}
            </section>
            <MetadataField
              metadata={vendorMetadata}
              label="Description"
              field="description"
            />
            <Disclosure defaultOpen>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Primary Vendor Information
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 content-start gap-5 w-full">
                      {vendorMetadata.vendor_type && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Vendor Type
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.vendor_type}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.assessed_for_ai_functionality && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Assessed for AI Functionality
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.assessed_for_ai_functionality}
                          </span>
                        </article>
                      )}
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Website"
                        field="website"
                      />
                      {vendorMetadata.address && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Address
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.address}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.number_of_employees > 0 && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Number of Employees
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.number_of_employees}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.ref_id && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Ref-ID
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.ref_id}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.inherent_risk && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Inherent Risk
                          </h4>
                          <span
                            className={`capitalize ${
                              attributeColors[
                                vendorMetadata.inherent_risk?.toLowerCase()
                              ]
                            } ${
                              attributeTextColors[vendorMetadata.inherent_risk]
                            }`}
                          >
                            {vendorMetadata.inherent_risk}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.rank_tier && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Rank Tier
                          </h4>
                          <span
                            className={`capitalize ${
                              attributeColors[
                                vendorMetadata.rank_tier?.toLowerCase()
                              ]
                            } ${attributeTextColors[vendorMetadata.rank_tier]}`}
                          >
                            {vendorMetadata.rank_tier}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.is_publicly_traded && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Publicly Traded
                          </h4>
                          <span
                            className={`capitalize ${attributeColors["true"]} ${attributeTextColors["true"]}`}
                          >
                            True
                          </span>
                        </article>
                      )}
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Risk Management</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      {vendorMetadata.primary_app_service && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Application Owner Email
                          </h4>
                          <span className="break-all text-b1-semi">
                            {vendorMetadata.primary_app_service}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.vendor_manager && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Vendor Manager
                          </h4>
                          {getEmailFromID(
                            allUsers,
                            vendorMetadata.vendor_manager
                          )}
                        </article>
                      )}
                      {vendorMetadata.help_ticket && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Help Ticket
                          </h4>
                          <span className="break-all text-b1-semi">
                            {vendorMetadata.help_ticket}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.sponsor && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Sponsor
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.sponsor}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.criticality && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Criticality
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.criticality}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.data_classification && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Data Classification
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.data_classification}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.last_review_date && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Last Review Date
                          </h4>
                          <span className="text-b1-semi">
                            {convertToUTCShortString(
                              vendorMetadata.last_review_date
                            )}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.next_review_date && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Next Review Date
                          </h4>
                          <span className="text-b1-semi">
                            {convertToUTCShortString(
                              vendorMetadata.next_review_date
                            )}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.reassessment_frequency && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Reassessment Frequency
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.reassessment_frequency}
                          </span>
                        </article>
                      )}
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Notes"
                        field="notes"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Additional Notes"
                        field="additional_notes"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Analyst Comments"
                        field="analyst_comments"
                      />
                      {vendorMetadata.review_checklist?.length > 0 && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Vendor Review Checklist
                          </h4>
                          <ul className="grid">
                            {vendorMetadata.review_checklist.map(
                              (checklist: string) => (
                                <span key={checklist} className="text-b1-semi">
                                  {checklist}
                                </span>
                              )
                            )}
                          </ul>
                        </article>
                      )}
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Additional Information"
                        field="additional_information"
                      />
                      {vendorMetadata.impacted_systems && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Impacted Systems
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.impacted_systems}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.sso_enabled && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            SSO Enabled
                          </h4>
                          <span
                            className={`capitalize ${attributeColors["true"]} ${attributeTextColors["true"]}`}
                          >
                            True
                          </span>
                        </article>
                      )}
                      {vendorMetadata.vendor_tier && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Vendor Tier
                          </h4>
                          <span
                            className={`capitalize ${
                              attributeColors[
                                vendorMetadata.vendor_tier?.toLowerCase()
                              ]
                            } ${
                              attributeTextColors[vendorMetadata.vendor_tier]
                            }`}
                          >
                            {vendorMetadata.vendor_tier}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.baa_executed && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Business Associate Agreement Executed
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.baa_executed}
                          </span>
                        </article>
                      )}
                      <MetadataField
                        metadata={vendorMetadata}
                        label="External Document URL"
                        field="external_document_url"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Vendor Contact Information
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      {vendorMetadata.contact_name && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Contact Name
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.contact_name}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.contact_email && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Contact Email
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.contact_email}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.contact_phone && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Contact Phone
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.contact_phone}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.location && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Location
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.location}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.data_host_location && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Data Host Location
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.data_host_location}
                          </span>
                        </article>
                      )}
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Scoping Details</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      {vendorMetadata.target_primary_application_service && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Target Primary Application Service
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.target_primary_application_service}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.authentication_protocol && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Authentication Protocol
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.authentication_protocol}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.is_PHI && (
                        <span
                          className={`dark:text-gray-300 text-b2-reg ${attributeColors.active}`}
                        >
                          PHI
                        </span>
                      )}
                      {vendorMetadata.is_PII && (
                        <span
                          className={`dark:text-gray-300 text-b2-reg ${attributeColors.active}`}
                        >
                          PII
                        </span>
                      )}
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Others</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      {vendorMetadata.workflow && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Workflow
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.workflow}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.dj_n_party_risks && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Dow Jones Nth Party Risks
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.dj_n_party_risks}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.internal_email_list && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Internal Email CC List
                          </h4>
                          <span className="break-all text-b1-semi">
                            {vendorMetadata.internal_email_list}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.business_user_name && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Name of Business User
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.business_user_name}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.department_head && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Head of Department
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.department_head}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.department && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Department
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.department}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.stage && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Stage
                          </h4>
                          <span className="text-b1-semi">
                            {vendorMetadata.stage}
                          </span>
                        </article>
                      )}
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Historical Data"
                        field="historical_data"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
          <section className="p-6 dark:bg-gray-800 rounded-2xl">
            <VendorTags vendorID={vendorID} tags={vendorMetadata.tags} />
          </section>
        </header>
      )}
    </>
  );
};

export default VendorMetadata;
